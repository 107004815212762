import React from 'react';
import Image from '../Image';

export default class ProductTypeResult extends React.Component {
    constructor(props) {
        super(props);
    }

    formattedPrice(price) {
        return Number(price) % 1 === 0 ? price.replace('.00', '') : Number(price).toFixed(2);
    }

    showPrice(item) {
        if (item.salePrice !== '' && item.salePrice !== 'null') {
            return (
                <div className="product-card__pricing proxima">
                    <span className="amount amount--sale-price">&pound;{this.formattedPrice(item.salePrice)}</span>
                    <span className="amount amount--old-price">&pound;{this.formattedPrice(item.regularPrice)}</span>
                </div>
            );
        }
        return (
            <div className="product-card__pricing proxima">
                <span className="amount">&pound;{this.formattedPrice(item.price)}</span>
            </div>
        );
    }

    render = () => {
        const { item } = this.props;
        const analytics = this.props.analytics || '';
        const { permalink, featuredImage, primaryCategory, title, activeProductTag, sourceCodeDescription } = item;
        const hasActiveTag = activeProductTag && activeProductTag.length === undefined;
        const productTitle =
            sourceCodeDescription !== '' && sourceCodeDescription !== undefined ? sourceCodeDescription : title;

        return (
            <article className="product-card">
                <a className={`product-card__link ${analytics}`} href={item.permalink}>
                    {hasActiveTag && (
                        <span className={`product-card__tag product-card__tag--${activeProductTag.key} proxima`}>
                            {activeProductTag.value}
                        </span>
                    )}
                    <Image uri={item.featuredImage} />
                    <h4 className="product-card__primary-category promixa">
                        <span key={item.primaryCategory} dangerouslySetInnerHTML={{ __html: item.primaryCategory }} />
                    </h4>
                    <h3 className="product-card__title sanomat" dangerouslySetInnerHTML={{ __html: productTitle }}></h3>
                    {this.showPrice(item)}
                </a>
            </article>
        );
    };
}
