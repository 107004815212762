import Query from '../Search/Query';
import BaseSearch from '../Search/Search';

export default class Search extends BaseSearch {
    constructor(term, tax, type, postPerPage, filters = null, sort = null) {
        super(null);

        const header = JSON.stringify({
            index: 'web_search',
        });

        let bulkQuery = header + '\n';
        bulkQuery += JSON.stringify(new Query(term, tax, type, postPerPage, 1, sort, filters)) + '\n';

        this.query = bulkQuery;
    }
}
