import React from 'react';
import PostTypeResult from '../Search/ResultTypes/PostTypeResult';

const LatestRelatedNewsSidebar = ({ results, title = 'You may also like' }) => {
    const relatedArticleContent = results.map(item => {
        return <PostTypeResult key={item._source.id} item={item._source} analytics="ga-article-related" />;
    });

    return (
        <>
            <header className="content-header">
                <div className="content-header__intro user-content">
                    <h2 className="content-header__title sanomat">{title}</h2>
                </div>
            </header>
            <div className="content-section__inner-content">
                <div className="">
                    <div className="">{relatedArticleContent}</div>
                </div>
            </div>
        </>
    );
};

export default LatestRelatedNewsSidebar;
