import React from 'react';

const PaginationItem = ({ type, page, disabled, onClick }) => {
    if (type === 'previous' || type === 'next') {
        const icon = type === 'previous' ? 'icon-left-chevron' : 'icon-right-chevron';
        const mobileButtonLabel = type.substr(0, 4);
        const buttonClasses = `react-pagination__${type}`;

        return (
            <button className={`react-pagination__${type}`} onClick={(e) => onClick(e)} disabled={disabled}>
                <i className={icon} />
                <span className="react-pagination__text react-pagination__text--mobile">{mobileButtonLabel}</span>
                <span className="react-pagination__text">{type} page</span>
            </button>
        );
    }

    return type === 'start-ellipsis' || type === 'end-ellipsis' ? (
        <div className="react-pagination__dots">…</div>
    ) : (
        <button className="page-number__btn" onClick={(e) => onClick(e)} disabled={disabled}>
            {type === 'page' ? <span>{page}</span> : <span>{type}</span>}
        </button>
    );
};

export default class PaginationBar extends React.Component {
    constructor(props) {
        super(props);

        const { currentPage, total, perPage } = this.props;
        const count = Math.ceil(total / perPage);

        this.state = {
            activePage: currentPage || 1,
            count: count || 1,
            boundaryCount: 1,
            siblingCount: 1,
            disabled: false,
        };
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevProps !== this.props) {
            const { currentPage, total, perPage } = this.props;
            const count = Math.ceil(total / perPage);
            const newState = {
                activePage: currentPage || 1,
                count: count || 1,
            };

            this.setState(newState);
        }
    };

    handleClick = (event, value) => {
        this.setState({ activePage: value });
        // Might need to enable this later for seo
        // this.props.history.push(`?page=${value}`);
        this.props.handlePageChange(value);
    };

    buttonPage(type) {
        const { activePage, count } = this.state;

        switch (type) {
            case 'first':
                return 1;
            case 'previous':
                return activePage - 1;
            case 'next':
                return activePage + 1;
            case 'last':
                return count;
            default:
                return null;
        }
    }

    range(start, end) {
        const length = end - start + 1;

        return Array.from({ length }, (_, i) => start + i);
    }

    buildItemList() {
        const { activePage, count, boundaryCount, siblingCount } = this.state;
        const startPages = this.range(1, Math.min(boundaryCount, count));
        const endPages = this.range(Math.max(count - boundaryCount + 1, boundaryCount + 1), count);
        const siblingsStart = Math.max(
            Math.min(activePage - siblingCount, count - boundaryCount - siblingCount * 2 - 1),
            boundaryCount + 2
        );
        const siblingsEnd = Math.min(
            Math.max(activePage + siblingCount, boundaryCount + siblingCount * 2 + 2),
            endPages[0] - 2
        );

        return [
            ...['previous'],
            ...startPages,
            ...(siblingsStart > boundaryCount + 2
                ? ['start-ellipsis']
                : boundaryCount + 1 < count - boundaryCount
                ? [boundaryCount + 1]
                : []),
            ...this.range(siblingsStart, siblingsEnd),
            ...(siblingsEnd < count - boundaryCount - 1
                ? ['end-ellipsis']
                : count - boundaryCount > boundaryCount
                ? [count - boundaryCount]
                : []),
            ...endPages,
            ...['next'],
        ];
    }

    getRenderItems() {
        const { activePage, count, disabled } = this.state;

        return this.buildItemList().map((item) => {
            if (typeof item === 'number') {
                return {
                    onClick: (event) => {
                        this.handleClick(event, item);
                    },
                    type: 'page',
                    page: item,
                    classNames: [
                        'react-pagination__item react-pagination__item--page',
                        [item === activePage ? 'react-pagination__item--active' : null],
                    ]
                        .filter(String)
                        .join(' '),
                    disabled,
                };
            }

            const itemDisabled =
                disabled ||
                (item.indexOf('ellipsis') === -1 &&
                    (item === 'next' || item === 'last' ? activePage >= count : activePage <= 1));

            let itemClasses = `react-pagination__item react-pagination__item--${item}`;

            if (itemDisabled) {
                itemClasses += ` react-pagination__item--disabled`;
            }

            return {
                onClick: (event) => {
                    this.handleClick(event, this.buttonPage(item));
                },
                type: item,
                page: this.buttonPage(item),
                classNames: itemClasses,
                disabled: itemDisabled,
            };
        });
    }

    render() {
        const { total } = this.props;

        if (total === 0) {
            return null;
        }

        return (
            <nav className="c-pagination react-pagination proxima">
                <div className="container">
                    <div className="react-pagination__inner">
                        <ul className="react-pagination__numbers">
                            {this.getRenderItems().map((item, index) => (
                                <li key={index} className={item.classNames}>
                                    {PaginationItem({ ...item })}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}
