import React, { Fragment } from 'react';
import ProductTypeResult from '../Search/ResultTypes/ProductTypeResult';

const RelatedProduct = ({ results }) => {
    const relatedProduct = results.map((item) => {
        return (
            <Fragment key={item._source.id}>
                <ProductTypeResult item={item._source} />
                <div className="button-row u-justify-center">
                    <a className="button button--outline" href={item._source.permalink}>Shop now</a>
                </div>
            </Fragment>
        );
    });

    return relatedProduct;
}

export default RelatedProduct;
