import React, { useState }  from 'react';
import PostTypeResult from '../Search/ResultTypes/PostTypeResult';
import PaginationBar from '../PaginationBar';

const RelatedArticles = ({ results, title = 'You may also like' }) => {
    
    const [page, setPage] = useState(1);

    const relatedArticleContent = results.map((item, index) => {
        if(index < 8) {
            return <PostTypeResult key={item._source.id} item={item._source} analytics="ga-article-related" />;
        } 
    });

    const relatedArticleContentPage2 = results.map((item, index) => {
        if(index >= 8) {
            return <PostTypeResult key={item._source.id} item={item._source} analytics="ga-article-related" />;
        }
    });

    const updatePage = (page) => {
        setPage(page);
    };

    const Pagination = () => {
        return (
            <nav className="c-pagination react-pagination proxima">
                <div className="container">
                    <div className="react-pagination__inner">
                        <ul className="react-pagination__numbers">
                            <li className={`react-pagination__item react-pagination__item--previous ${ page === 1 ? "react-pagination__item--disabled" : ""}`}>
                                <button className="react-pagination__previous" data-scrolltarget="#related-news" disabled={ page=== 1 } onClick={() => { updatePage(1) }}>
                                    <i className="icon-left-chevron"></i>
                                    <span className="react-pagination__text react-pagination__text--mobile">prev</span>
                                    <span className="react-pagination__text">previous page</span>
                                </button>
                            </li>
                            <li className={`react-pagination__item react-pagination__item--next ${ page === 2 ? "react-pagination__item--disabled" : ""}`}>
                                <button className="react-pagination__next" data-scrolltarget="#related-news" disabled={ page === 2} onClick={() => { updatePage(2) }}>
                                    <i className="icon-right-chevron"></i>
                                    <span className="react-pagination__text react-pagination__text--mobile">next</span>
                                    <span className="react-pagination__text">next page</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }

    return (
        <div className="container">
            <header className="content-header">
                <div className="content-header__intro user-content">
                    <h2 className="content-header__title sanomat">{title}</h2>
                </div>
            </header>
            <div className="content-section__inner-content container">
                <div id="related-news" className="article-grid article-grid--no-ads">
                    { page === 1 ? (
                        <div className="article-grid__column">{relatedArticleContent}</div>
                    ) : (
                        <div className="article-grid__column 2">{relatedArticleContentPage2}</div>
                    ) }
                </div>
            </div>
            <Pagination/>
        </div>
    );
};

export default RelatedArticles;
