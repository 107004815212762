import { CheckUserDetails } from './tools';

const userDetails = new CheckUserDetails();
if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => userDetails.renderAds());
} else {
    userDetails.renderAds();
}

import '../images';
import '../svgs';

import './modules';
import './components';
import { FontLoader } from './tools';

import RemoveFocusState from 'remove-focus-state'

const fonts = new FontLoader([
    { name: 'Proxima', weight: 400 },
    { name: 'Sanomat', weight: 700 },
    { name: 'Sanomat', weight: 400 },
    { name: 'Publico', weight: 400 }
]);

fonts.load();

new RemoveFocusState();

document.getElementsByClassName('no-js')[0].classList.remove('no-js');

// fix for gift card date formats
$(function() {
    function setDateFormat() {
        $(this).datepicker("option", "dateFormat", "d MM yy");
        $(this).off('focus', setDateFormat);
    }

    $('.woocommerce_gc_giftcard_form .datepicker').on('focus', setDateFormat);
});