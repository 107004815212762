import React, { useState, useEffect, useRef } from 'react';
import { renderToString } from 'react-dom/server';

import Loader from '../Search/Loader';
import consoleDebug from '../../tools/debug';

const PaywallThreshold = ({ postId }) => {
    const [thresholdData, setThresholdData] = useState(null);
    const [totalViewed, setTotalViewed] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchThreshold();
    }, []);

    const fetchThreshold = async () => {
        await fetch(`${wp_ajax.ajax_url}?action=paywallThreshold&post_id=${postId}`)
            .then(response => response.json())
            .then(responseJSON => {
                if (!responseJSON.success) {
                    consoleDebug(['Error fetching paywall threshold', responseJSON], 'error');
                } else {
                    consoleDebug('Debugging paywall threshold response: start', 'log');
                    consoleDebug(responseJSON, 'log');
                    consoleDebug('Debugging paywall threshold response: end', 'log');

                    if (responseJSON.display) {
                        consoleDebug('Post requires paywall threshold', 'log');

                        setThresholdData(responseJSON.data);
                    } else {
                        consoleDebug('Post does not require paywall threshold', 'log');
                    }

                    // Attempt to refresh post content if below the paywall threshold
                    const { shouldPaywall, threshold } = responseJSON.data;

                    checkThreshold(shouldPaywall, threshold, responseJSON.display);
                }

                setLoading(false);
            });
    };

    const checkThreshold = async (shouldPaywall, threshold, display = true) => {
        // If display value is false, it represents a subscribed user and we should
        // adjust the paywall content render to the result of the shouldPaywall variable
        // and exit early else it will attempt to count the page towards the threshold.
        if (!display) {
            consoleDebug('Paywall not required as user is a subscriber or has digital access rights', 'log');

            fetchPostContent(shouldPaywall);

            return;
        }

        const viewedPostsLocalStorage = localStorage.getItem('viewedPosts');

        // If the post should be paywalled i.e. is a hard paywall or user is not a
        // digital subscriber or purchased a digital version then exist early and return
        // paywall if not already rendered.
        if (shouldPaywall) {
            consoleDebug('Paywall required, no need to update threshold data', 'log');

            if (viewedPostsLocalStorage) {
                const viewedPosts = JSON.parse(atob(viewedPostsLocalStorage));

                if (viewedPosts.posts) {
                    setTotalViewed(viewedPosts.posts.length);
                }
            }
            // Hide the threshold count if on a hard paywall content item.
            setThresholdData(null);
            fetchPostContent(shouldPaywall);

            return;
        }

        consoleDebug(`Viewed posts: ${viewedPostsLocalStorage}`, 'log');

        if (!viewedPostsLocalStorage) {
            const viewedPostsData = {
                posts: [postId],
                createdAt: new Date(),
            };

            localStorage.setItem('viewedPosts', btoa(JSON.stringify(viewedPostsData)));

            consoleDebug(
                `No previous viewedPost storage data available, creating new dataset: ${JSON.stringify(
                    viewedPostsData,
                )}`,
                'log',
            );

            setTotalViewed(1);
            fetchPostContent(shouldPaywall);

            return;
        }

        const viewedPosts = JSON.parse(atob(viewedPostsLocalStorage));
        consoleDebug(viewedPosts, 'log');

        // Handle edge case where no posts are stored in local storage
        if (viewedPosts.posts.length === 0) {
            const viewedPostsData = {
                posts: [postId],
                createdAt: new Date(),
            };

            localStorage.setItem('viewedPosts', btoa(JSON.stringify(viewedPostsData)));

            setTotalViewed(1);
            fetchPostContent(shouldPaywall);

            return;
        }

        // Check if local storage data has expired
        const { createdAt } = viewedPosts;
        const now = new Date();
        const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
        const timeDiffInMs = now.getTime() - Date.parse(createdAt);

        consoleDebug(`Time difference in ms: ${timeDiffInMs}`, 'log');

        if (timeDiffInMs >= thirtyDaysInMs) {
            // Data has expired, reset local storage data
            const viewedPostsData = {
                posts: [postId],
                createdAt: now,
            };

            localStorage.setItem('viewedPosts', btoa(JSON.stringify(viewedPostsData)));

            setTotalViewed(1);
            fetchPostContent(shouldPaywall);

            consoleDebug('viewedPosts data has expired, local storage has been reset', 'log');

            return;
        }

        // If current post id is already in the local storage posts array then exit early
        if (viewedPosts.posts.includes(postId)) {
            const totalPostsViewed = viewedPosts.posts.length > threshold ? threshold : viewedPosts.posts.length;
            setTotalViewed(totalPostsViewed);
            fetchPostContent(shouldPaywall);

            consoleDebug(`Post ${postId} already in viewed posts`, 'log');

            return;
        }

        // Check if current posts array count is more than or equal to the threshold,
        // if so, then don't increase count and enforce paywall render
        if (viewedPosts.posts.length >= threshold) {
            setTotalViewed(threshold);
            fetchPostContent(true);

            consoleDebug('User has meet free content threshold, paywall now enforced', 'log');

            return;
        }

        // Current post needs to be added to the local storage posts array
        viewedPosts.posts.push(postId);

        localStorage.setItem('viewedPosts', btoa(JSON.stringify(viewedPosts)));
        const totalPostsViewed = viewedPosts.posts.length > threshold ? threshold : viewedPosts.posts.length;
        setTotalViewed(totalPostsViewed);
        setTotalViewed(viewedPosts.posts.length);
        fetchPostContent(shouldPaywall);
    };

    const fetchPostContent = async shouldPaywall => {
        const paywallRendered = document.querySelector('[data-behaviour="paywall-rendered"]');

        if (shouldPaywall && paywallRendered) {
            consoleDebug('Exit early, paywall required and is already rendered', 'log');

            return;
        }

        if (!shouldPaywall && !paywallRendered) {
            consoleDebug('Exit early, paywall not required and is not already rendered from server', 'log');

            return;
        }

        const postContent = document.querySelector('[data-behaviour="post-content"]');
        const currentContent = postContent.innerHTML;
        postContent.innerHTML = renderToString(<Loader />);

        await fetch(`${wp_ajax.ajax_url}?action=paywallContent&post_id=${postId}&should_paywall=${shouldPaywall}`)
            .then(response => response.json())
            .then(responseJSON => {
                if (!responseJSON.success) {
                    consoleDebug(['Error fetching paywall content', responseJSON], 'error');

                    postContent.innerHTML = currentContent;
                } else {
                    const { content } = responseJSON.data;

                    postContent.innerHTML = content ? content : currentContent;
                }
            });
    };

    const renderThresholdBanner = () => (
        <>
            {thresholdData !== null && (
                <aside className="threshold-banner">
                    <div className="threshold-banner__inner proxima">
                        <span className="leading-text">You have viewed </span>
                        <strong>
                            {totalViewed} of {thresholdData.threshold}
                        </strong>{' '}
                        free articles
                    </div>
                    <a className="button button--subscribe" href={`${thresholdData.subscription_url}?utm_source=website&utm_medium=subs+counter+button&utm_campaign=main_subs_counter_button`}>
                        Subscribe <span className="leading-text">for unlimited access</span>
                    </a>
                </aside>
            )}
        </>
    );

    return <>{loading && thresholdData === null ? <div className="threshold-banner-spacer"><Loader /></div> : renderThresholdBanner()}</>;
};

export default PaywallThreshold;