import React from 'react';
import ReactRender from '../../tools/react-render';
import Search from './Search';
import RelatedArticles from './RelatedArticles';
import RelatedProducts from './RelatedProducts';
import RelatedIssueArticles from './RelatedIssueArticles';
import RelatedArticle from './RelatedArticle';
import RelatedIssueContent from './RelatedIssueContent';
import RelatedProduct from './RelatedProduct';
import LatestRelatedNewsBlock from './LatestRelatedNewsBlock';
import LatestRelatedNewsSidebar from './LatestRelatedNewsSidebar';

const automationTypeConfig = [
    { Component: RelatedArticles, type: 'related-articles', postType: 'post', count: 16 },
    { Component: LatestRelatedNewsBlock, type: 'latest-news-block', postType: 'post', count: 3 },
    { Component: LatestRelatedNewsSidebar, type: 'latest-news-sidebar', postType: 'post', count: 4 },
    {
        Component: RelatedProducts,
        type: 'related-products',
        postType: 'product',
        count: 4,
        excludedCategories: ['DVD', 'Motorsport &amp; Racing DVDs', 'Motorsport & Racing DVDs', 'Uncategorised'],
    },
    { Component: RelatedIssueArticles, type: 'related-issue-articles', postType: 'issue_content', count: 4 },
    { Component: RelatedArticle, type: 'related-article', postType: 'post', count: 1 },
    { Component: RelatedIssueContent, type: 'related-issue-content', postType: 'issue_content', count: 1 },
    {
        Component: RelatedProduct,
        type: 'related-product',
        postType: 'product',
        count: 1,
        excludedCategories: ['DVD', 'Motorsport &amp; Racing DVDs', 'Motorsport & Racing DVDs', 'Uncategorised'],
    },
];

const getSearchResults = async automationData => {
    const searchResponses = [];

    for (const [postType, automations] of Object.entries(automationData)) {
        let postTypeCount = 0;

        automations.forEach(automation => {
            postTypeCount +=
                automation.countOverride !== null
                    ? parseInt(automation.countOverride)
                    : automationTypeConfig.find(config => config.type === automation.automationType).count;
        });

        let filters = null;

        if (postType === 'product') {
            filters = { in_stock: true };
        }

        const currentId = automations[0].currentId;

        if (currentId !== undefined) {
            if (filters === null) {
                filters = { exclude_ids: [currentId] };
            } else {
                filters.exclude_ids = [currentId];
            }
        }

        let excludedCategories = automationTypeConfig.find(config => config.type === automations[0].automationType)
            .excludedCategories;

        if (automations[0].productCategories !== null) {
            excludedCategories = excludedCategories.filter(x => !automations[0].productCategories.includes(x));
        }

        if (excludedCategories !== undefined) {
            if (filters === null) {
                filters = { exclude_categories: excludedCategories.join(', ') };
            } else {
                filters.exclude_categories = excludedCategories.join(', ');
            }
        }

        const search = new Search(automations[0].tags.join(', '), null, postType, postTypeCount, filters);
        const searchResults = await search.getResults();

        searchResponses[postType] = (searchResponses[postType] || []).concat(searchResults.responses[0].hits.hits);
    }

    return searchResponses;
};

const buildAutomations = async (tagAutomationContent, groupedAutomationData) => {
    let searchResults = await getSearchResults(groupedAutomationData);

    tagAutomationContent.forEach(el => {
        const tags = JSON.parse(el.getAttribute('data-tags'));
        const automationType = el.getAttribute('data-automation-type');
        const countOverride = el.getAttribute('data-count-override');
        const titleOverride = el.getAttribute('data-title-override');
        const { postType, count, Component } = automationTypeConfig.find(config => config.type === automationType);
        const resultCount = countOverride !== null ? parseInt(countOverride) : count;
        const results = searchResults[postType].splice(0, resultCount);

        let componentProps = {
            results,
        };

        if (titleOverride !== null) {
            componentProps.title = titleOverride;
        }

        ReactRender(<Component {...componentProps} />, el);
    });
};

const tagAutomationContent = document.querySelectorAll('[data-behaviour="tag-automation"]');

if (tagAutomationContent.length > 0) {
    const automationData = [];

    tagAutomationContent.forEach(el => {
        const tags = JSON.parse(el.getAttribute('data-tags'));
        const automationType = el.getAttribute('data-automation-type');
        const currentId = el.getAttribute('data-current-post');
        const productCategories = JSON.parse(el.getAttribute('data-product-categories'));
        const countOverride = el.getAttribute('data-count-override');
        const { postType } = automationTypeConfig.find(config => config.type === automationType);

        automationData.push({ tags, automationType, postType, currentId, productCategories, countOverride });
    });

    const groupedAutomationData = automationData.reduce((grouped, obj) => {
        const postType = obj.postType;

        grouped[postType] = (grouped[postType] || []).concat(obj);

        return grouped;
    }, {});

    buildAutomations(tagAutomationContent, groupedAutomationData);
}
