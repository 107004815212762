require('./existing-users-login.svg');
require('./svg-loader.svg');
require('./map.svg');
require('./motorsport-logo.svg');
require('./msm-sponsored-icon.svg');
require('./new-user-registration.svg');
require('./circular-tick.svg');
require('./circular-cross.svg');
require('./play-icon.svg');
require('./podcast-icon.svg');
require('./great-read-icon.svg');
require('./visa.svg');
require('./amex.svg');
require('./sepa.svg');
require('./multibanco.svg');
require('./discover.svg');
require('./ideal.svg');
require('./giropay.svg');
require('./eps.svg');
require('./diners.svg');
require('./p24.svg');
require('./sofort.svg');
require('./bancontact.svg');
require('./alipay.svg');
require('./wechat.svg');
require('./mastercard.svg');
require('./jcb.svg');
require('./maestro.svg');
require('./credit-card.svg');
require('./paypal.svg');
require('./visa-debit.svg');
require('./flag-outlines.svg');
require('./yu.svg');
require('./cs.svg');
require('./rh.svg');
require('./dd.svg');
require('./international.svg');
const svgFlags = require.context('svg-country-flags/svg/', true, /\.svg$/);
svgFlags.keys().forEach(flag => svgFlags(flag));
