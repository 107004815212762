import exists from '../tools/exists';

// Global Components
import('./basket');
import('./navigation');
import('./optin-form-validation');

if( 
    exists('[data-behaviour*="form-redirect"]') ||
    exists('[data-behaviour*="url-redirect"]')
) {
    import('./redirect-to');
}

if( exists('.single_add_to_cart_button') ) {
    import('./add-to-cart');
}

if( exists('[data-behaviour="toggle-faq"]') ) {
    import('./faqs');
}

if( exists('[data-behaviour="back-to-top"]') ) {
    import('./back-top-top');
}

if( exists('[data-behaviour="collapsible"]') ) {
    import('./collapsible');
}

if( exists('[data-toggle-behaviour="collapsible"]') ) {
    import('./cart-toggle-additional-fields');
}

if( exists('[data-behaviour="dd-transaction-activation"]') ) {
    import('./dd-transactions');
}

if( exists('.js-link-disabled') ) {
    import('./disable-links');
}

if( exists('[data-behaviour="dropdown-select"]') ) {
    import('./dropdown-select');
}

if( exists('[data-behaviour="dropdown-toggle"]') ) {
    import('./dropdown-toggle');
}

if( exists('[data-behaviour="filter-date"]') ) {
    import('./filter-date');
}

if( 
    exists('[data-behaviour="filter-product"]') || 
    exists('[data-behaviour="trigger-filter-form"]') 
) {
    import('./filter-product');
}

if( 
    exists('.gfield') || 
    exists('.validation_message') || 
    exists('.gform_confirmation_message') 
) {
    import('./form-validation');
}

if( exists('[data-behaviour="toggle-menu"]') ) {
    import('./footer-toggle');
}

if( 
    exists('.c-year-selector') || 
    exists('.c-latest-articles') 
) {
    import('./issues');
}

if( exists('[data-behaviour="google-map"]') ) {
    import('./map');
}

if( 
    exists('[data-behaviour="circuit-images"]') || 
    exists('[data-behaviour="issue-slider"]')  
) {
    import('./photoswipe');
}

if( exists('[data-behaviour="product-price"]') ) {
    import('./product-variation-price');
}

if( exists('[data-behaviour="scroll-to"]') ) {
    import('./scroll-to');
}

if( exists('[data-behaviour="empty-search"]') ) {
    import('./search');
}

if( exists('.c-ad-space') ) {
    import('./sidebar-ads');
}

if( 
    exists('[data-behaviour="circuits-slider"]') ||
    exists('[data-behaviour="database-slider"]') ||
    exists('[data-behaviour="database-slider-nested"]') ||
    exists('[data-behaviour="author-slider"]') ||
    exists('[data-behaviour="mobile-slider"]') ||
    exists('[data-behaviour="cta-slider"]') ||
    exists('[data-behaviour="news-slider"]') ||
    exists('[data-behaviour="simple-slider"]') ||
    exists('[data-behaviour="issue-slider"]') ||
    exists('[data-behaviour="features-slider"]') ||
    exists('[data-behaviour="recently-viewed"]') ||
    exists('[data-behaviour="product-carousel"]') ||
    exists('.woocommerce-product-gallery')
) {
    import('./sliders');
}

if( exists('[data-behaviour="close-sticky-ad"]') ) {
    import('./sticky-ads');
}

if( exists('[data-behaviour="toggle-comparisons"]') ) {
    import('./subscription-comparisons');
}

if( exists('[data-behaviour="toggle-comparisons"]') ) {
    import('./subscription-comparisons');
}

if( 
    exists('[data-behaviour="toggle-tab"]') ||
    exists('[data-behaviour="toggle-tab-plain"]')
) {
    import('./tabs');
}

if( exists('body.single-post') ) {
    import('./view-count');
}

if( 
    exists('[data-behaviour="add-or-remove-from-wishlist"]') ||
    exists('[data-behaviour="toggle-wishlist-visibility"]')
) {
    import('./wishlist');
}

if( exists('[data-behaviour="quantity"]') ) {
    import('./cart-quantity');
}

if( exists('[data-behaviour="checkout-fields"]') ) {
    import('./checkout-fields');
}

if( exists('[data-behaviour="toggle"]') ) {
    import('./toggle');
}

if( exists('[data-behaviour="nested-table"]') ) {
    import('./nested-tables');
}

if( 
    exists('[data-behaviour="driver-seasons-table"]') ||
    exists('[data-behaviour="team-seasons-table"]') ||
    exists('[data-behaviour="driver-teams-table"]') ||
    exists('[data-behaviour="driver-races-table"]') ||
    exists('[data-behaviour="team-races-table"]') ||
    exists('[data-behaviour="circuit-races-table"]') ||
    exists('[data-behaviour="race-qualifying-table"]') ||
    exists('[data-behaviour="race-results-table"]') ||
    exists('[data-behaviour="championship-standings-table"]') ||
    exists('[data-behaviour="championship-races-table"]')
) {
    import('./database-tables');
}

if( 
    exists('[data-behaviour="championship-data-box"]') ||
    exists('[data-behaviour="race-data-box"]') ||
    exists('[data-behaviour="driver-data-box"]') ||
    exists('[data-behaviour="circuit-data-box"]')
) {
    import('./database-data-boxes');
}

if( exists('[data-behaviour="category-grid"]') ) {
    import('./category-grid');
}

if( exists('[data-behaviour="row-toggle"]') ) {
    import('./toggle');
}


if( 
    exists('[data-behaviour="lazy-load"]')  
) {
    import('./lazy-load');
}

window.onload = () => {

    if( 
        exists('[data-behaviour="react-adv-search"]') ||
        exists('.react-pagination') ||
        exists('.datatable-paging')
    ) {
        import('./pagination-scrollTop');
    }

};