import React from 'react';
import Image from '../Image';
import SpinnerSVG from '../../../../svgs/spinner.svg';

export default class PostTypeResult extends React.Component {
    constructor(props) {
        super(props);
    }

    getDate(date) {
        const strtotime = require('locutus/php/datetime/strtotime');

        const formattedDate = new Date(strtotime(date)* 1000);
        const dateTimeFormat = new Intl.DateTimeFormat('en', {year: 'numeric', month: 'long', day: '2-digit'});
        const [{value: month}, , {value: day}, , {value: year}] = dateTimeFormat.formatToParts(formattedDate);

        return `${day} ${month} ${year}`;
    }

    render = () => {
        const { item } = this.props;
        const analytics = this.props.analytics || '';
        const categories = item.categories.map(category => <span key={category}>{category}</span>);

        return (
            <article className="article-item">
                <a className={`article-item__link ${analytics}`} href={item.permalink}>
                    <div className="article-item__image article-item__image--cropped" style={{
                        width: '100%',
                        backgroundColor: 'rgba(80,80,80,0.05)',
                        backgroundImage: `url(${SpinnerSVG})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        display: 'flex'
                    }}>
                        <Image uri={item.featuredImage}/>
                    </div>
                    <div className="article-item__content">
                        <div className="article-item__meta tag tag--group proxima">{categories}</div>
                        <h2 className="article-item__title">{item.title}</h2>
                        <div className="article-item__info proxima">
                            <time className="article-item__date" dateTime={item.post_date}>
                                {this.getDate(item.post_date)}&nbsp;
                            </time>
                            <div className="article-item__author">{item.author}</div>
                        </div>
                    </div>
                </a>
            </article>
        );
    };
}
