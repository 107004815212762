import React from 'react';
import IssueContentTypeResult from '../Search/ResultTypes/IssueContentTypeResult';

const RelatedIssueArticles = ({ results, title = 'From the Archive' }) => {
    const relatedIssueContent = results.map(item => {
        return <IssueContentTypeResult key={item._source.id} item={item._source} />;
    });

    return (
        <div className="container">
            <header className="content-header">
                <div className="content-header__intro user-content">
                    <h2 className="content-header__title sanomat">{title}</h2>
                </div>
            </header>
            <div className="content-section__inner-content container">
                <div className="article-grid">
                    <div className="article-grid__column">{relatedIssueContent}</div>
                </div>
            </div>
        </div>
    );
};

export default RelatedIssueArticles;
