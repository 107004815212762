import React from 'react';
import Image from '../Image';
import SpinnerSVG from '../../../../svgs/spinner.svg';

export default class IssueContentTypeResult extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        const { item } = this.props;

        return (
            <article className="article-item">
                <a className="article-item__link" href={item.permalink}>
                    <div className="article-item__image article-item__image--cropped" style={{
                        width: '100%',
                        backgroundColor: 'rgba(80,80,80,0.05)',
                        backgroundImage: `url(${SpinnerSVG})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        display: 'flex'
                    }}>
                        <Image type="issue-content" item={item} uri="" />
                    </div>
                    <div className="article-item__content">
                        <div className="article-item__meta tag tag--group proxima">Archive</div>
                        <h2 className="article-item__title">{item.title}</h2>
                        <div className="article-item__info proxima">
                            <span className="article-item__date">{item.archiveEdition.post_title} Issue</span>
                            <div className="article-item__author">{item.author}</div>
                        </div>
                    </div>
                </a>
            </article>
        );
    };
}
