import React from 'react';
import PostTypeResult from '../Search/ResultTypes/PostTypeResult';

const headerEl = document.querySelector('.c-site-header');
const relatedArticlesEl = document.querySelector('#database-related-articles');

const LatestRelatedNewsBlock = ({ results, title = 'You may also like' }) => {
    
    const relatedArticleContent = results.map(item => {
        return <PostTypeResult key={item._source.id} item={item._source} analytics="ga-article-related" />;
    });

    const relatedScroll = (e) => {
        e.preventDefault();
        const headerHeight = headerEl.offsetHeight;
        const y = relatedArticlesEl.getBoundingClientRect().top + window.pageYOffset - headerHeight;
        window.scrollTo({top: y, behavior: 'smooth'});
    }

    return (
        <div className="container">
            <header className="content-header">
                <div className="content-header__intro user-content">
                    <h2 className="content-header__title sanomat">{title}</h2>
                </div>
            </header>
            <div className="content-section__inner-content container">
                <div className="article-grid article-grid--no-ads">
                    <div className="article-grid__column article-grid__column--3">{relatedArticleContent}</div>
                </div>  
                <a href="#database-related-articles" onClick={(e) => relatedScroll(e)} className="button button--outline">More News</a>
            </div>
        </div>
    );
};

export default LatestRelatedNewsBlock;
