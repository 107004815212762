import React from 'react';
import IssueContentTypeResult from '../Search/ResultTypes/IssueContentTypeResult';

const RelatedIssueContent = ({results}) => {
    const relatedIssueContent = results.map((item) => {
        return <IssueContentTypeResult key={item._source.id} item={item._source} />
    });

    return relatedIssueContent;
}

export default RelatedIssueContent;
