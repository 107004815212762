export default class Query {
    constructor(term, type = null, count = 16, page = 1, sort = null, filters = null) {
        let query = {
            query: {
                bool: {
                    must: [
                        {
                            multi_match: {
                                query: term,
                                type: 'phrase_prefix',
                                operator: 'or',
                                fields: [
                                    'title^10',
                                    'permalink',
                                    'drivers^5',
                                    'teamEntrants^5',
                                    'teams^5',
                                    'circuitFastestRaceLap^5',
                                    'circuitFastestQualifyingLap^5',
                                ],
                            },
                        },
                        {
                            match: {
                                postStatus: 'publish',
                            },
                        },
                    ],
                    must_not: [],
                    filter: [],
                },
            },
            size: count,
            from: (page - 1) * count,
        };

        if (type == '' || type === null) {
            query.query.bool.must.push({
                terms: {
                    postType: ['driver', 'race', 'championship', 'team', 'circuit'],
                },
            });
        } else {
            query.query.bool.must.push({
                match_phrase: {
                    postType: type,
                },
            });
        }

        return query;
    }
}
