import React from 'react';
export default class Image extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            image: null,
            uri: '',
            nostyle: props.nostyle,
            type: props.type || '',
            item: props.item || null,
            hasErrored: false
        };
    }

    componentDidMount() {
        // TODO: Make image loading process nicer, it's janky.
        // Would be good to make the image load in the background and show loading state
        const { uri } = this.props;

        this.setState({ image: uri.replace('http:', 'https:'), loading: false });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { uri } = this.props;

        if (prevProps.uri !== uri) {
            this.setState({
                image: uri.replace('http:', 'https:'),
            });
        }
    }

    getLoadingImage() {
        const { type } = this.state;

        if (type.includes('issue-content')) {
            return <div className="article-item__issue" style={{ display: 'none' }}></div>;
        }

        return <img />;
    }

    getIssueContentImage() {
        const { item, hasErrored } = this.state;
        const archivePageNumber = parseInt(item.archivePage);
        const mediaBase = item.postType === 'specialissue_content' ? 'special' : 'archive';

        return (
            <div className="article-item__issue">
                <img
                    src={`https://media.motorsportmagazine.com/${mediaBase}/${item.archiveEdition.post_name}/180px/${archivePageNumber}.jpg.webp`}
                    onError={({ currentTarget }) => {
                        this.setState({ hasErrored: true });
                        currentTarget.onerror = null; // prevents looping
                        if(!hasErrored) {
                            currentTarget.src = `https://media.motorsportmagazine.com/${mediaBase}/${item.archiveEdition.post_name}/180px/${archivePageNumber}.jpg`;
                        }
                    }}
                />
                {item.archiveDoubleSpread && (
                    <img
                        src={`https://media.motorsportmagazine.com/archive/${
                            item.archiveEdition.post_name
                        }/180px/${archivePageNumber + 1}.jpg.webp`}
                        onError={({ currentTarget }) => {
                            this.setState({ hasErrored: true });
                            currentTarget.onerror = null; // prevents looping
                            if(!hasErrored) {
                                currentTarget.src = `https://media.motorsportmagazine.com/${mediaBase}/${item.archiveEdition.post_name}/180px/${archivePageNumber}.jpg`;
                            }
                        }}
                    />
                )}
            </div>
        );
    }

    getImage() {
        const { loading, uri, nostyle, type, hasErrored } = this.state;
        let { image } = this.state;
        const originalImage = image;

        if (loading) {
            return this.getLoadingImage();
        }

        if (type.includes('issue-content')) {
            return this.getIssueContentImage();
        }

        if (image.includes('media.motorsportmagazine.com')) {
            image = `${image}.webp`;
        }

        return (
            <img
                src={image}
                onError={({ currentTarget }) => {
                    this.setState({ hasErrored: true });
                    currentTarget.onerror = null; // prevents looping
                    if (image.includes('media.motorsportmagazine.com') && !hasErrored) {
                        currentTarget.src = originalImage;
                    }
                }}
                data-src={uri}
                style={
                    nostyle
                        ? {}
                        : {
                              objectFit: 'cover',
                              width: '100%',
                              height: '100%',
                          }
                }
            />
        );
    }

    render = () => {
        return this.getImage();
    };
}
