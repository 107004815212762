export default class Query {
    constructor(term, tax = null, types = [], count = 16, page = 1, sort = null, filters = null) {
        let query = {
            query: {
                function_score: {
                    query: {
                        bool: {
                            must: [
                                {
                                    multi_match: {
                                        query: term,
                                        type: 'cross_fields',
                                        operator: 'or',
                                        fields: [
                                            'title',
                                            'tags^5',
                                            'permalink^3',
                                            'content',
                                            'archiveEdition.post_title^10',
                                            'featuredImage',
                                            'author',
                                        ],
                                    },
                                },
                                {
                                    match: {
                                        postStatus: 'publish',
                                    },
                                },
                            ],
                            must_not: [
                                {
                                    match_phrase: {
                                        productVisiblity: 'hidden',
                                    },
                                },
                            ],
                            filter: [],
                        },
                    },
                    functions: [
                        {
                            filter: {
                                range: {
                                    post_date: { gte: 'now-1y', lte: 'now' },
                                },
                            },
                            weight: 5,
                        },
                        {
                            filter: {
                                range: {
                                    post_date: { gte: 'now-2y', lt: 'now-1y' },
                                },
                            },
                            weight: 2,
                        },
                    ],
                    boost_mode: 'multiply',
                },
            },
            size: count,
            from: (page - 1) * count,
        };

        if (sort === null) {
            query.sort = {
                _score: 'desc',
            };
        }
        if (sort === 'price') {
            query.sort = {
                price: 'desc',
            };
        }
        if (sort === 'priceAsc') {
            query.sort = {
                price: 'asc',
            };
        }

        if (sort === 'dateDesc') {
            query.sort = {
                post_date: 'desc',
            };
        }

        if (sort === 'dateAsc') {
            query.sort = {
                post_date: 'asc',
            };
        }

        if (types !== '' && types !== null) {
            query.query.function_score.query.bool.should = [];

            if ( Array.isArray(types) ) {

                types.forEach(type => {
                    query.query.function_score.query.bool.should.push({
                        bool: {
                            must: [
                                {
                                    match_phrase: {
                                        postType: type,
                                    },
                                },
                            ],
                        },
                    });
                });

            } else {

                query.query.function_score.query.bool.should.push({
                    bool: {
                        must: [
                            {
                                match_phrase: {
                                    postType: types,
                                },
                            },
                        ],
                    },
                });

            }

            query.query.function_score.query.bool.minimum_should_match = 1;
        }

        if (tax !== '' && tax !== null) {
            query.query.function_score.query.bool.must.push({
                match_phrase: {
                    categories: tax,
                },
            });
        }

        if (filters !== null) {
            if (filters.category !== undefined) {
                query.query.function_score.query.bool.must.push({
                    match_phrase: {
                        categories: filters.category,
                    },
                });
            }

            let dateFilter = false;

            if (filters.year !== undefined && filters.month !== undefined) {
                let date = filters.year + '-' + filters.month;

                if (types.includes('post')) {
                    const postShouldMatchIndex = this.getPostShouldMatchIndex(query);

                    query.query.function_score.query.bool.should[postShouldMatchIndex].bool.must.push({
                        range: {
                            post_date: {
                                gte: `${date}||/M`,
                                lte: `${date}||/M`,
                                format: 'yyyy-MM',
                            },
                        },
                    });
                }

                if (types.includes('issue_content')) {
                    const issueContentShouldMatchIndex = this.getIssueContentShouldMatchIndex(query);
                    const issueContentDate = new Date(`${filters.year}-${filters.month}-01`);
                    const issueContentMonth = issueContentDate.toLocaleString('default', { month: 'long' });

                    query.query.function_score.query.bool.should[issueContentShouldMatchIndex].bool.must.push({
                        match_phrase: {
                            'archiveEdition.post_title': `${issueContentMonth} ${filters.year}`,
                        },
                    });
                }
            } else if (filters.month !== undefined) {
                let date = filters.month;

                if (types.includes('issue_content')) {
                    const issueContentDate = new Date(`1970-${filters.month}-01`);
                    const issueContentMonth = issueContentDate.toLocaleString('default', { month: 'long' });

                    const issueContentShouldMatchIndex = this.getIssueContentShouldMatchIndex(query);

                    query.query.function_score.query.bool.should[issueContentShouldMatchIndex].bool.must.push({
                        match_phrase: {
                            'archiveEdition.post_title': issueContentMonth,
                        },
                    });
                }

                if (types.includes('post')) {
                    const currentYear = new Date().getFullYear();
                    const postShouldMatchIndex = this.getPostShouldMatchIndex(query);

                    date = `${currentYear}-${date}`;

                    query.query.function_score.query.bool.should[postShouldMatchIndex].bool.must.push({
                        range: {
                            post_date: {
                                gte: `${date}||/M`,
                                lte: `${date}||/M`,
                                format: 'yyyy-MM',
                            },
                        },
                    });
                }
            } else if (filters.year !== undefined) {
                const date = filters.year;

                if (types.includes('issue_content')) {
                    const issueContentShouldMatchIndex = this.getIssueContentShouldMatchIndex(query);

                    query.query.function_score.query.bool.should[issueContentShouldMatchIndex].bool.must.push({
                        match_phrase: {
                            'archiveEdition.post_title': date,
                        },
                    });
                }

                if (types.includes('post')) {
                    const postShouldMatchIndex = this.getPostShouldMatchIndex(query);

                    query.query.function_score.query.bool.should[postShouldMatchIndex].bool.must.push({
                        range: {
                            post_date: {
                                gte: `${date}||/y`,
                                lte: `${date}||/y`,
                                format: 'yyyy',
                            },
                        },
                    });
                }
            }

            if (filters.decade !== undefined) {
                if (filters.year === undefined) {
                    query.query.function_score.query.bool.minimum_should_match = 2;
                }

                if (types.includes('issue_content') && filters.year === undefined) {
                    const issueContentShouldMatchIndex = this.getIssueContentShouldMatchIndex(query);

                    for (let i = 0; i < 10; i++) {
                        query.query.function_score.query.bool.should.push({
                            match_phrase: {
                                'archiveEdition.post_title': parseInt(filters.decade) + i,
                            },
                        });
                    }
                }

                if (types.includes('post')) {
                    if (filters.year === undefined && filters.month === undefined) {
                        query.query.function_score.query.bool.should.push({
                            range: {
                                post_date: {
                                    gte: `${parseInt(filters.decade)}||/y`,
                                    lte: `${parseInt(filters.decade) + 9}||/y`,
                                    format: 'yyyy',
                                },
                            },
                        });
                    }
                }
            }

            if (types.includes('product')) {
                if (filters.status !== undefined) {
                    switch (filters.status) {
                        case 'on-sale':
                            query.query.function_score.query.bool.must.push({
                                regexp: {
                                    salePrice: '.+',
                                },
                            });
                            break;
                        case 'limited-stock':
                            query.query.function_score.query.bool.must.push({
                                match: {
                                    isLimitedStock: 1,
                                },
                            });
                            break;
                    }
                }

                if (filters.price !== undefined) {
                    query.query.function_score.query.bool.must.push({
                        range: {
                            price: {
                                gte: filters.price[0],
                                lte: filters.price[1],
                            },
                        },
                    });
                }

                if (filters.in_stock !== undefined) {
                    query.query.function_score.query.bool.must.push({
                        match: {
                            inStock: true,
                        },
                    });
                }
            }

            if (filters.exclude_ids !== undefined) {
                query.query.function_score.query.bool.must_not.push({
                    ids: {
                        values: filters.exclude_ids,
                    },
                });
            }

            if (filters.exclude_categories !== undefined) {
                query.query.function_score.query.bool.must_not.push({
                    match_phrase: {
                        categories: filters.exclude_categories,
                    },
                });
            }
        }

        return query;
    }

    getPostShouldMatchIndex = query => {
        return this.getPostTypeShoudMatchIndex(query, 'post');
    };

    getIssueContentShouldMatchIndex = query => {
        return this.getPostTypeShoudMatchIndex(query, 'issue_content');
    };

    getPostTypeShoudMatchIndex = (query, postType) => {
        return query.query.function_score.query.bool.should.findIndex(cond => {
            const condPostTypeIndex = cond.bool.must.findIndex(condPostType => {
                if (condPostType.hasOwnProperty('match_phrase')) {
                    return condPostType.match_phrase.postType === postType;
                }
            });

            return condPostTypeIndex !== -1;
        });
    };
}
