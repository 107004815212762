import React from 'react';
import ReactRender from '../../tools/react-render';
import PaywallThreshold from './PaywallThreshold';

const paywallThreshold = document.querySelector('[data-behaviour="react-paywall-threshold"]');

if (paywallThreshold) {
    const postId = paywallThreshold.dataset.postId;

    ReactRender(<PaywallThreshold postId={postId} />, paywallThreshold);
}
