import React from 'react';
import PostTypeResult from '../Search/ResultTypes/PostTypeResult';

const RelatedArticle = ({results}) => {
    const relatedArticle = results.map((item) => {
        return <PostTypeResult key={item._source.id} item={item._source} />
    });

    return relatedArticle;
}

export default RelatedArticle;
