import React from 'react';
import ProductTypeResult from '../Search/ResultTypes/ProductTypeResult';

const RelatedProducts = ({ results, title = 'Related Products' }) => {
    const relatedProducts = results.map(item => {
        return <ProductTypeResult key={item._source.id} item={item._source} analytics="ga-product-related" />;
    });

    return (
        <div className="container">
            <header className="content-header">
                <div className="content-header__intro user-content">
                    <h2 className="content-header__title sanomat">{title}</h2>
                </div>
                <div className="content-header__button">
                    <a className="button button--outline button--full" href="/shop">
                        Visit shop
                    </a>
                </div>
            </header>
            <div className="content-section__inner-content container">
                <div className="latest-products__grid">{relatedProducts}</div>
            </div>
        </div>
    );
};

export default RelatedProducts;
